// This file was automatically generated and should not be edited.;
import type { SVGProps } from 'react';
import { Ref, forwardRef } from 'react';
const SvgPasswordCheck = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>,
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    viewBox="0 0 24 24"
    ref={ref}
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M12.75 16a.75.75 0 0 1-.75.75H5A2.75 2.75 0 0 1 2.25 14V8A2.75 2.75 0 0 1 5 5.25h14A2.75 2.75 0 0 1 21.75 8v5a.75.75 0 0 1-1.5 0V8c0-.69-.56-1.25-1.25-1.25H5c-.69 0-1.25.56-1.25 1.25v6c0 .69.56 1.25 1.25 1.25h7a.75.75 0 0 1 .75.75m1.22 1.97a.75.75 0 0 1 1.06 0l1.47 1.47 3.47-3.47a.75.75 0 1 1 1.06 1.06l-4 4a.75.75 0 0 1-1.06 0l-2-2a.75.75 0 0 1 0-1.06m-1.402-6.47a.75.75 0 1 0-1.115-1.003l-.01.011a.75.75 0 1 0 1.114 1.004zm3.944-1.059a.75.75 0 0 1 .055 1.06l-.01.01a.75.75 0 1 1-1.114-1.003l.01-.01a.75.75 0 0 1 1.059-.057m-7.945 1.06a.75.75 0 1 0-1.114-1.004l-.01.011a.75.75 0 1 0 1.114 1.004z"
      clipRule="evenodd"
    />
  </svg>
);
export const PasswordCheckIcon = forwardRef(SvgPasswordCheck);
